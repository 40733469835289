import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  fetchNewPdfDownloads,
  fetchPdfById,
  fetchPdfDownloads,
} from "services/downloads";
import { AttachmentGroups } from "types/downloads";
import { AttachmentGroupList } from "components/Downloads/AttachmentGroupList";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function Attachment() {
  const { t } = useTranslation();

  const [pdfDownload, setPdfDownload] = useState<AttachmentGroups[]>([]);
  const [newPdfDownloads, setNewPdfDownloads] = useState<AttachmentGroups[]>(
    []
  );
  const [certificate, setCertificate] = useState<AttachmentGroups[]>([]);
  const [manuals, setManuals] = useState<AttachmentGroups[]>([]);

  const fetchAttachments = async () => {
    try {
      const pdfDownloads: AttachmentGroups[] = await fetchPdfDownloads();
      setPdfDownload(pdfDownloads);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const fetchNewAttachments = async () => {
    try {
      const newPdfDownloads: AttachmentGroups[] = await fetchNewPdfDownloads();
      setNewPdfDownloads(newPdfDownloads);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const fetchCertificates = async () => {
    try {
      const certificate: AttachmentGroups[] = await fetchPdfById(3);
      setCertificate(certificate);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const fetchManuals = async () => {
    try {
      const manuals: AttachmentGroups[] = await fetchPdfById(4);
      setManuals(manuals);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    fetchAttachments();
    fetchNewAttachments();
    fetchCertificates();
    fetchManuals();
  }, []);

  return (
    <main>
      <section className="section-secondary">
        <Container>
          <Row>
            <Col md="6">
              <>
                <h2>Downloads</h2>
                <hr />
                <b>{pdfDownload[0]?.names[0]?.title}</b>
                <AttachmentGroupList attachmentGroups={pdfDownload} />
              </>
            </Col>

            <Col md="6">
              <>
                <h2>{t("attachments.news")}</h2>
                <hr />
                <b>{newPdfDownloads[0]?.names[0]?.title}</b>
                <AttachmentGroupList attachmentGroups={newPdfDownloads} />
              </>
            </Col>
          </Row>
          <Row>
            {certificate.length <= 0 ? (
              <></>
            ) : (
              <Col md="6">
                <>
                  <h2>{t("attachments.certificate")}</h2>
                  <hr />
                  <b>{certificate[0]?.names[0]?.title}</b>
                  <AttachmentGroupList attachmentGroups={certificate} />
                </>
              </Col>
            )}
            {manuals.length <= 0 ? (
              <></>
            ) : (
              <Col md="6">
                <>
                  <h2>{t("attachments.manuals")}</h2>
                  <hr />
                  <b>{manuals[0]?.names[0]?.title}</b>
                  <AttachmentGroupList attachmentGroups={manuals} />
                </>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </main>
  );
}
