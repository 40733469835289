//Hooks
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

//Services
import { fetchPdfById } from "services/downloads";

//Types
import { AttachmentGroups } from "types/downloads";

//Components
import NavLink from "components/routing/NavLink";

export interface NavDropDownDownloadsSubmenusProps {
  onClick?: () => void;
}

export function NavDropDownDownloadsSubmenus({
  onClick,
}: NavDropDownDownloadsSubmenusProps) {
  const { t } = useTranslation();

  const [attachment, setAttachment] = useState<AttachmentGroups[]>([]);
  const [newAttachment, setNewAttachment] = useState<AttachmentGroups[]>([]);
  const [certificate, setCertificate] = useState<AttachmentGroups[]>([]);
  const [manuals, setManuals] = useState<AttachmentGroups[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [nshow, setNShow] = useState<boolean>(false);
  const [cshow, setCShow] = useState<boolean>(false);
  const [mshow, setMShow] = useState<boolean>(false);

  const [mainDropDownExpanded, setMainDropDownExpanded] = useState(false);
  useState(false);

  const getAllAttachments = async () => {
    try {
      //Attachments
      const attachment = await fetchPdfById(1);
      setAttachment(attachment);
      //New Attachments
      const newAttachment = await fetchPdfById(5);
      setNewAttachment(newAttachment);
      //TODO COMMENT IN IF BACKEND IS READY
      /*const certificates = await fetchPdfById(3);
      setCertificate(certificates);
      //Manuals
      const manuals = await fetchPdfById(4);
      setManuals(manuals);*/
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);

      return Promise.reject(errorMessage);
    }
  };

  const reset = () => {
    setMainDropDownExpanded(false);

    if (onClick !== undefined) {
      onClick();
    }
  };

  useEffect(() => {
    getAllAttachments();
  }, []);

  return (
    <NavDropdown
      title="Downloads"
      className="my-1"
      onClick={() => {
        if (mainDropDownExpanded === true) {
          reset();
        } else {
          setMainDropDownExpanded(true);
          setShow(false);
        }
      }}
    >
      {attachment.map((a) => (
        <NavDropdown.Item
          as="span"
          eventKey="attachments"
          key={"attachment-" + a.id}
        >
          <NavDropdown
            title={a.names[0]?.title || t("attachments.brochure")}
            drop={window.innerWidth >= 1070 ? "end" : "down"}
            onClick={(event) => {
              event.stopPropagation();
              setShow((p) => !p);
            }}
            autoClose={true}
            show={show}
          >
            {show && (
              <div>
                <b
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-end justify-content-end mx-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    setShow(false);
                  }}
                >
                  X
                </b>
                {attachment[0].attachments.map((aa) => (
                  <a href={aa.filepath} target="blank" key={aa.id}>
                    <NavDropdown.Item
                      as="span"
                      eventKey="attachments"
                      key={aa.id}
                    >
                      <b>{aa.names[0].title}</b>
                    </NavDropdown.Item>
                  </a>
                ))}
              </div>
            )}
          </NavDropdown>
        </NavDropdown.Item>
      ))}
      {newAttachment.map((na) => (
        <NavDropdown.Item as="span" eventKey="news" key={"news-" + na.id}>
          <NavDropdown
            title={na.names[0]?.title || t("attachments.new")}
            drop={window.innerWidth >= 1070 ? "end" : "down"}
            onClick={(event) => {
              event.stopPropagation();
              setNShow((p) => !p);
            }}
            autoClose={true}
            show={nshow}
          >
            {nshow && (
              <div>
                <b
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-end justify-content-end mx-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    setNShow(false);
                  }}
                >
                  X
                </b>
                {newAttachment[0].attachments.map((na) => (
                  <a href={na.filepath} target="blank" key={na.id}>
                    <NavDropdown.Item as="span" eventKey="news" key={na.id}>
                      <b>{na.names[0].title}</b>
                    </NavDropdown.Item>
                  </a>
                ))}
              </div>
            )}
          </NavDropdown>
        </NavDropdown.Item>
      ))}
      {certificate.map((c) => (
        <NavDropdown.Item
          as="span"
          eventKey="certificate"
          key={"certificate-" + c.id}
        >
          <NavDropdown
            title={c.names[0]?.title || t("attachments.certificate")}
            drop={window.innerWidth >= 1070 ? "end" : "down"}
            onClick={(event) => {
              event.stopPropagation();
              setNShow((p) => !p);
            }}
            autoClose={true}
            show={cshow}
          >
            {cshow && (
              <div>
                <b
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-end justify-content-end mx-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    setCShow(false);
                  }}
                >
                  X
                </b>
                {certificate[0].attachments.map((c) => (
                  <a href={c.filepath} target="blank">
                    <NavDropdown.Item
                      as="span"
                      eventKey="certificate"
                      key={c.id}
                    >
                      <b>{c.names[0].title}</b>
                    </NavDropdown.Item>
                  </a>
                ))}
              </div>
            )}
          </NavDropdown>
        </NavDropdown.Item>
      ))}
      {manuals.map((m) => (
        <NavDropdown.Item as="span" eventKey="manuals" key={"manuals-" + m.id}>
          <NavDropdown
            title={m.names[0]?.title || t("attachments.manuals")}
            drop={window.innerWidth >= 1070 ? "end" : "down"}
            onClick={(event) => {
              event.stopPropagation();
              setMShow((p) => !p);
            }}
            autoClose={true}
            show={mshow}
          >
            {mshow && (
              <div style={{ overflow: "scroll", height: "300px" }}>
                <b
                  style={{ cursor: "pointer" }}
                  className="d-flex align-items-end justify-content-end mx-2"
                  onClick={(event) => {
                    event.stopPropagation();
                    setMShow(false);
                  }}
                >
                  X
                </b>
                {manuals[0].attachments.map((m) => (
                  <a href={m.filepath} target="blank" key={m.id}>
                    <NavDropdown.Item as="span" eventKey="manuals" key={m.id}>
                      <b>{m.names[0].title}</b>
                    </NavDropdown.Item>
                  </a>
                ))}
              </div>
            )}
          </NavDropdown>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}
