import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { ArticleProduct } from "../../types/articleProduct";
import ReactPlayer from "react-player";
import Container from "react-bootstrap/Container";

import altImage from "assets/ProductCard/altImage.png";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { fetchProductDownload } from "services/articleProducts";
import { useTranslation } from "react-i18next";
import ProductBreadcrumb from "components/ProductBreadcrumb/ProductBreadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export interface ProductDetailsListItemProps {
  productDetail: ArticleProduct;
}

export function ProductDetailsListItem({
  productDetail,
}: ProductDetailsListItemProps) {
  const downloadAttachmentsAvailable = productDetail.attachments.filter(
    (pd) => !pd.filepath.endsWith(".mp4")
  );

  const { t } = useTranslation();

  const getProductDownload = async (id: number) => {
    try {
      const blob: Blob = await fetchProductDownload(id);
      const url = window.URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = productDetail.number + ".pdf";
      anchor.click();

      window.URL.revokeObjectURL(url);
      anchor.remove();
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : (err as string);
      toast.error(errorMessage);
    }
  };

  const convertHtmlCharacter = (text: string | number) => {
    if (typeof text === "number") {
      return text;
    }
    return text
      .replaceAll("&lt;", "<")
      .replaceAll("&quot;", '"')
      .replaceAll("&#039;", "'")
      .replaceAll("&gt", ">")
      .replaceAll("&circ;", "^")
      .replaceAll("&tilde;", "~")
      .replaceAll("&ndash;", "–")
      .replaceAll("&amp;", "&");
  };

  return (
    <>
      <Container className="pt-3">
        <ProductBreadcrumb />
      </Container>
      <Container fluid>
        <Row className="mt-5">
          <Col md={5}>
            <Image
              style={{ height: 700, objectFit: "contain" }}
              src={
                !productDetail.images.length
                  ? altImage
                  : productDetail.images[0]?.src
              }
            />
          </Col>
          <Col md={4}>
            <h2 className="d-none d-xl-block">
              {productDetail.descriptions[0].title}
            </h2>
            <h2 className="d-sm-block d-xl-none" style={{ fontSize: "19px" }}>
              {productDetail.descriptions[0].title}
            </h2>
            {productDetail.descriptions[0].description !== "" ? (
              <span className="">
                {convertHtmlCharacter(
                  productDetail.descriptions[0]?.description
                )}
              </span>
            ) : (
              <></>
            )}

            <Table striped bordered hover className="mt-2">
              <tbody>
                <tr>
                  <td>{t("product.articlenumber")}</td>
                  <td>{productDetail.number}</td>
                </tr>
                {productDetail.properties.map((productDetailProp) => (
                  <tr key={productDetailProp.id}>
                    <td>
                      {productDetailProp.names[0] ? (
                        convertHtmlCharacter(productDetailProp.names[0]?.name)
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {productDetailProp.value !== null
                        ? productDetailProp.value?.value
                        : ""}{" "}
                      {productDetailProp.unit !== null ? (
                        typeof productDetailProp.unit.names[0].name ===
                          "string" &&
                        productDetailProp.unit.names[0].name.startsWith(
                          "https"
                        ) ? (
                          <a
                            href={productDetailProp.unit.names[0].name}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {" "}
                            {productDetailProp.unit.names[0].name}{" "}
                          </a>
                        ) : (
                          convertHtmlCharacter(
                            productDetailProp.unit.names[0].name
                          )
                        )
                      ) : (
                        <span></span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Row>
              <Col md="12" lg="4">
                <Button
                  variant="danger"
                  onClick={() => getProductDownload(productDetail.id)}
                >
                  {t("product.button")}
                </Button>
              </Col>
              <Col md="12" lg="8">
                {downloadAttachmentsAvailable.length > 0 ? (
                  <span>Downloads:</span>
                ) : (
                  <></>
                )}
                <ul>
                  {downloadAttachmentsAvailable.map((pd) => (
                    <li
                      className="attachment"
                      key={pd.filepath}
                      onClick={() => window.open(pd.filepath)}
                    >
                      <FontAwesomeIcon icon={faDownload} /> {pd.names[0].title}
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>
          </Col>
          <Col lg="3" md="4" sm="8" xs="8" className="align-self-center ">
            {productDetail.attachments
              .filter((pd) => pd.filepath.endsWith(".mp4"))
              .map((pd) => (
                <ReactPlayer
                  width="99%"
                  height="54%"
                  url={pd.filepath}
                  key={pd.filepath}
                  config={{
                    file: {
                      attributes: {
                        type: "video/mp4",
                        crossOrigin: "true",
                      },
                    },
                  }}
                  controls
                />
              ))}
          </Col>
        </Row>
      </Container>
    </>
  );
}
